
export default {
  components: {},
  data() {
    return {
      imgList: [
        "https://qiniu.qsmiaoyu180.cn/website/sy_banner02%402x.png",
        "https://qiniu.qsmiaoyu180.cn/website/sy_banner03%402x.png",
        "https://qiniu.qsmiaoyu180.cn/website/sy_banner%402x.png"
      ],
      ShowImg: 0,  // 表示当前显示的图片
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
      technology: [
        {
          title: '大数据平台',
          img: 'https://qiniu.qsmiaoyu180.cn/website/sy_01%402x.png',
          content: 'AI计算机平台模型深度学习基础模型'
        },
        {
          title: '语音测评',
          img: 'https://qiniu.qsmiaoyu180.cn/website/sy_02%402x.png',
          content: '“听读背诵讲”五项语音基础能力测评'
        },
        {
          title: '智能媒体',
          img: 'https://qiniu.qsmiaoyu180.cn/website/sy_03%402x.png',
          content: '多媒体智能剪辑、合成技术'
        },
        {
          title: '语音识别',
          img: 'https://qiniu.qsmiaoyu180.cn/website/sy_04%402x.png',
          content: '语音识别语音唤醒、语音合成'
        },
        {
          title: '人工智能',
          img: 'https://qiniu.qsmiaoyu180.cn/website/sy_05%402x.png',
          content: '海量优质数据交互、人机对话'
        },
      ],
      expertList: [
        {
          name: '姚喜双',
          introduce: '国家语委教授，语用所所长，国家语委普通话与文字应用培训测试中心主任、《中国诗词大会》总策划',
          suggest: '姚教授在为我们勾勒和策划课程体系之初，就提出了青少年汉语能力培养与提升一定要注重“三个结合”：“一是结合不同年龄段青少年汉语学习规律；二是结合新的部编语文教材/大纲教学计划/内容；三是结合个性化需求及特点。"',
        },
        {
          name: '严平',
          introduce: '中国人民大学教育学博士/副教授',
          suggest: '“大多数国人的听、说、读能力都有待提升，这不仅仅是语文这个学科最新政策的需要，更是现代社会学习本身的诉求，或者说这些能力的提高，对所有其他学科的能力提升，均大有裨益！”',
        },
        {
          name: '程建',
          introduce: '深圳文史专家/副研究员',
          suggest: '“世界是由语言命名和建构的。人类的强大来自于语言，文明的传承依赖于语言。我们每一个人只有学好语言，才能认识世界，与世界和谐共处。"',
        },
        {
          name: '刘恒鑫',
          introduce: '北京语言大学语言学及应用语言学博士',
          suggest: '“语言能力从人体行为表现来说，一方面是外部信息的输入，另一方面是自身信息的输出，如何让输出等于甚至大于输入是每个人都渴望达到的境界。语言能力=社交能力！"',
        },
      ],

    };
  },
  mounted() {
    // window.addEventListener("resize", function () {
    //   console.log(document.body.clientWidth);
    // }, false)


    this.setTimeoFun()
  },
  methods: {

    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun(type) {// 停止定时器            // 重新执行定时器
      type == '移入' ? clearTimeout(this.start) : this.setTimeoFun()
    },
    setTimeoFun() {
      this.start = setInterval(() => {
        this.NextFun()
      }, 1500)
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle(fun) {
      if (this.flag) {
        this.flag = false;
        fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true;
        }, 1200); // 节流间隔时间
      }
    },
    // 上一张
    PrevFun() {
      if (this.ShowImg !== 0) {
        this.ShowImg--
      } else {
        this.ShowImg = this.imgList.length - 1
      }
    },
    // 下一张
    NextFun() {
      if (this.ShowImg !== this.imgList.length - 1) {
        this.ShowImg++
      } else {
        this.ShowImg = 0
      }
    },
  }
};
