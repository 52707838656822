
  <div id="home">
    <div class="SwiperBox" @mouseenter="MouseFun('移入')" @mouseleave="MouseFun('移出')">
      <!-- 图片 -->
      <img :class="['imgCss', ShowImg == index ? 'ShowCss' : '']" :src="item" v-for="(item, index) in imgList"
        :key="index" />
      <!-- 下方指示点容器 -->
      <div class="instBox">
        <div v-for="(item, index) in imgList.length" :key="index" @click="ShowImg = index"
          :class="['inst', ShowImg == index ? 'instActv' : '']">
        </div>
      </div>

    </div>
    <!-- 核心技术 -->
    <img class="hxjs" src="../assets/img/home/hxjs@2x.png" alt="">
    <div class="technology_list">
      <div class="technology_item" v-for="(item, index) in technology" :key="index">
        <div class="technology_title">{{ item.title }}</div>
        <img class="technology_img" :src="item.img" alt="">
        <div class="technology_content">{{ item.content }}</div>
      </div>

    </div>
    <!-- 专家建议 -->
    <div class="expert">
      <img class="hxjs" src="../assets/img/home/zjjy@2x.png" alt="">
      <div class="expert_list">
        <div class="expert_item" v-for="(item, index) in expertList" :key="index">
          <div class="expert_name">{{ item.name }}</div>
          <div class="expert_introduce">{{ item.introduce }}</div>
          <div class="expert_suggest">{{ item.suggest }}</div>
        </div>
      </div>
    </div>
    <!-- 课程体系设计团队 -->
    <img class="kctx" src="../assets/img/home/kctx@2x.png" alt="">
    <div class="team">
      <div class="team_left">
        <div class="team_left_top">
          <img class="sy_xqls" src="https://qiniu.qsmiaoyu180.cn/website/sy_xqls%402x.png" alt="">
          <div class="teaName">薛强</div>
          <div class="teacher">老师</div>
        </div>
        <p>
          深圳市政府督学，国培教育专家，广东省暨深圳市继续教育培训专家，国内资深语文教研员，深圳市中小学语文学会理事、秘书长。从事语文教学教研工作四十余年，参编著作十余部，撰写论文数十篇。主持过国家级、市级教学教研课题研究十几项。被教育部课程教材研究所评为“义务教育小学语文优秀教研员”；被中央教科院评为“科研教改先进实验工作者”。
        </p>
        <p>
          薛强老师认为：新的部编语文教材较大比重增加了中小学生的听、读能力训练，国学内容阅读学习量均大幅增加，更加注重孩子的阅读能力养成及综合能力和口头表达能力提升。但是众多的中小学校、一线语文老师和家长对此应对和准备不够充分！因此，在薛老师的带领下与各年级一线语文名师一起设计了清声妙语的课程体系。
        </p>
      </div>
      <div class="team_right">
        <div class="team_top">
          <img class="sy_zxjls" src="https://qiniu.qsmiaoyu180.cn/website/sy_zxjls%402x.png" alt="">
          <div>
            <div class="team_left_top">
              <div class="teaName">周小君</div>
              <div class="teacher">老师</div>
            </div>
            <p class="zhou">
              清声妙语语言表达能力提升训练体系设计者，中国语文报刊协会演讲口才分会副秘书长，全国青少年演讲与口才提升计划测评师/讲师，《演讲与口才》杂志社讲师，深圳长青老龄大学优秀教师，名师工作室主持人。从事青少年语言教学工作十余年，著有并出版《我是小小演说家》等系列教材。
            </p>
          </div>
        </div>
        <div class="team_top">
          <img class="sy_zxjls" src="https://qiniu.qsmiaoyu180.cn/website/sy_gxtls%402x.png" alt="">
          <div>
            <div class="team_left_top">
              <div class="teaName">郭晓婷</div>
              <div class="teacher">老师</div>
            </div>
            <p class="zhou">
              清声妙语语言艺术训练体系设计者，全国青少年演讲与口才提升计划测评师/讲师。著有并出版《我是小小演说家》等系列教材。
            </p>
          </div>

        </div>
      </div>

    </div>
    <!-- 产品与技术 -->
    <img class="sy_cpyjs" src="https://qiniu.qsmiaoyu180.cn/website/sy_cpyjs%402x.png" alt="">
    <!-- 合作伙伴 -->
    <img class="sy_hzhb" src="../assets/img/home/sy_hzhb@2x.png" alt="">
    <div class="buddy">
      <img class="buddyImg" src="https://qiniu.qsmiaoyu180.cn/website/kdxf%402x.png" alt="">
      <img class="buddyImg" src="https://qiniu.qsmiaoyu180.cn/website/qqzw%402x.png" alt="" style="marginLeft: 20px;">
      <img class="buddyImg" src="https://qiniu.qsmiaoyu180.cn/website/tykj%402x.png" alt="" style="marginLeft: 20px;">
      <img class="buddyImg" src="https://qiniu.qsmiaoyu180.cn/website/ymx%402x.png" alt="" style="marginLeft: 20px;">
      <img class="buddyImg" src="https://qiniu.qsmiaoyu180.cn/website/aly%402x.png" alt="">
      <img class="buddyImg" src="https://qiniu.qsmiaoyu180.cn/website/qny%402x.png" alt="" style="marginLeft: 20px;">
      <img class="buddyImg" src="https://qiniu.qsmiaoyu180.cn/website/txkj%402x.png" alt="" style="marginLeft: 20px;">


    </div>
  </div>
